<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="application.pages"
    />
  </div>
</template>

<script>
import service from '../api/centerCostService'
import {
  CargillCrudMetaView,
  responseToOptions,
  helpers
} from '@cargill/shared'
import _ from 'lodash'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service
    }
  },
  created() {
    const getMeta = async () => {
      const meta = await service.getMeta()
      const ctx = await service.getValidationContext()
      ctx.centerTypeOptions = responseToOptions(ctx.centerTypeOptions)
      ctx.centerOptions = responseToOptions(ctx.centerOptions)
      ctx.unityOptions = responseToOptions(ctx.unityOptions)
      const centerOptionsById = _.keyBy(ctx.centerOptions, (x) => x.value)

      _.forIn(ctx.centersByCenterType, (centers, centerType) => {
        ctx.centersByCenterType[centerType] = centers.map(
          (center) => centerOptionsById[center]
        )
      })

      const centerField = meta.fields.find((field) => field.id == 'center')
      const centerTypeField = meta.fields.find(
        (field) => field.id == 'centerType'
      )

      meta.fields.find((field) => field.id == 'storageCostUnity').options =
        ctx.unityOptions

      centerTypeField.options = ctx.centerTypeOptions
      centerField.options = ctx.centerOptions

      const getCenterOptions = (value) =>
        value?.value == null
          ? ctx.centerOptions
          : ctx.centersByCenterType[value.value]

      centerTypeField.onValueChange = (changedValue, fields) => {
        helpers.updateOptions(
          this,
          centerField,
          fields.center,
          getCenterOptions(changedValue)
        )
      }

      return meta
    }
    getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
